import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field,FieldArray } from 'redux-form';
import { FormGroup, Row, Button,Label } from 'reactstrap';

import styles from './styles.module.scss';

import { treatmentResultRecordsForm } from '../../../../constants/FormConstants';
import * as Validators from '../../../../util/Validators';
import {
  isExportAndLogs,
  isBreakBulkJobType,
  isBreakBulkOrExportOfLogsAndMethylBromide,
  isMBLogsInHold,
  isImportOrExportAndContainer,
  isImportOrExportContainerAndMethylBromide,
  isExportOtherMBApples,
} from '../../../../util/JobTypeHelper';
import {
  isMethylBromide,
  isVaporPhos,
  isAlPNonVessel,
  isEthylFormate,
  isCallington1Shot,
} from '../../../../util/TreatmentTypeHelper';

import RenderField from '../../../fields/RenderField';
import CheckboxField from '../../../fields/CheckboxField';
import SearchableSelect from '../../../fields/SearchableSelect';
import DatePickerField from '../../../fields/DatePickerField';
import RecaptureReadingsFieldArray from '../TreatmentResultRecordsMonitoringSection/RecaptureReadingsFieldArray';
import moment from 'moment';


const propTypes = {
  technicians: PropTypes.array.isRequired,
  canEditJob: PropTypes.bool,
  jobType: PropTypes.object.isRequired,
  treatmentType: PropTypes.string,
  actions: PropTypes.object.isRequired,
  showNoEndPhaseMessage: PropTypes.bool,
  sectionTouched: PropTypes.bool,
};

class TreatmentResultRecordsUncoveringSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
    };
  }

  onEditing(isEditing) {
    this.setState({ isEditing });
  }
  componentDidMount() {
    const { actions } = this.props;
    if (this.isPassedByDefault()) {
      actions.changeFieldValue(treatmentResultRecordsForm, 'passed', true);
    }
  }

  isPassedByDefault() {
    const { jobType, treatmentType } = this.props;
    return isMethylBromide(treatmentType) && isBreakBulkJobType(jobType);
  }
  getFumoReadingTimes=(currentTreatmentResultRecord)=>{
    var efReadings =  currentTreatmentResultRecord.efConcentrationReadingItem?currentTreatmentResultRecord.efConcentrationReadingItem.filter(Boolean):null;
   var efReadings = currentTreatmentResultRecord.efConcentrationReadingItem;
   var initialPhase = efReadings?efReadings.find(x => x.phase ==0):null
    var finalReadingsEF = efReadings?efReadings.find(x=>x.phase==2):null
    var initialReadingValues ={readingValue : "no value",readTime : 'no value'}
    var finalReadingValues ={readingValue : "no value",readTime : 'no value'}
    if(initialPhase &&finalReadingsEF){
    initialReadingValues = this.getStartFumoReadings(initialPhase)
     finalReadingValues = this.getReadings(finalReadingsEF)
    }
    return  { initialReadingValues,finalReadingValues}
  }

  getReadings=(initialPhase)=>{
    var lowestReadingLocation = "";
    var lowestReadingDateTime;
  var readingList =[]
    if(initialPhase !=null){
      if(initialPhase.frontEf !=null | initialPhase.frontEf != undefined)
      readingList = ([...readingList,initialPhase.frontEf])
      if(initialPhase.middleEf !=null | initialPhase.middleEf != undefined)
      readingList = ([...readingList,initialPhase.middleEf])
      if(initialPhase.backEf !=null | initialPhase.backEf != undefined)
      readingList = ([...readingList,initialPhase.backEf])
    }
    var lowestReading  = Math.min(...readingList)
    return  { readingValue : lowestReading,readTime : moment(initialPhase.readingDateTime)}

  }
  getStartFumoReadings=(initialPhase)=>{
  var readingList =[]
    if(initialPhase !=null){
      if(initialPhase.frontEf !=null | initialPhase.frontEf != undefined)
      readingList = ([...readingList,initialPhase.frontEf])
      if(initialPhase.middleEf !=null | initialPhase.middleEf != undefined)
      readingList = ([...readingList,initialPhase.middleEf])
      if(initialPhase.backEf !=null | initialPhase.backEf != undefined)
      readingList = ([...readingList,initialPhase.backEf])
    }
    var lowestReading  = Math.min(...readingList)
    
    return  { readingValue : lowestReading,readTime : moment(initialPhase.readingDateTime)}
  }
  getFumoTime=(startDatetime, endDateTime)=>{
      const timeDifference = endDateTime - startDatetime;
      const secondsDifference = timeDifference / 1000;
      const hours = Math.floor((secondsDifference % (3600 * 24)) / 3600);
      const minutes = Math.floor((secondsDifference % 3600) / 60)/100;
      const totDif = hours+minutes
      return totDif;
  }



  render() {
    const {
      technicians,
      canEditJob,
      jobType,
      treatmentType,
      showNoEndPhaseMessage,
      sectionTouched,
      jobId,
      currentJob,
      currentTreatmentResultRecord,
    } = this.props;
    const commodity = currentJob.treatmentTypeDetails.commodity;
    const standardId = currentJob.treatmentDetails.standardId;
    const treatmentTypeId = currentJob.treatmentDetails.treatmentTypeId
    const isCoveredContainer = null;//currentJob.treatmentTypeDetails.covered?currentJob.treatmentTypeDetails.covered:null
      var percentage = [{name:80,id:'80'} ,{name:90,id:'90'}]
      var percentageChamber = [{name:80,id:'80'}]
      var isoTanksLogs=[{name:'ISO Tank 1',id:1},{name:'ISO Tank 2',id:2},{name:'ISO Tank 3',id:3},{name:'ISO Tank 4',id:4}]
    const validationOn = sectionTouched;
    const isApples = isExportOtherMBApples(jobType,treatmentTypeId,commodity,standardId)
    var lowestValues = isEthylFormate(treatmentType) && (currentTreatmentResultRecord.efConcentrationReadingItem!=null
      &&currentTreatmentResultRecord.efConcentrationReadingItem.length>0)? 
    this.getFumoReadingTimes(currentTreatmentResultRecord):0;
    var numOfHours = isEthylFormate(treatmentType)&& lowestValues!=null &&lowestValues.finalReadingValues!=undefined && lowestValues.finalReadingValues.readTime!='no value' ?this.getFumoTime(lowestValues.initialReadingValues.readTime,lowestValues.finalReadingValues.readTime):0
    return (
      <div>
        {showNoEndPhaseMessage  && !isEthylFormate(treatmentType) && (
          <p className={styles.errorNote}>
            A monitoring reading for <strong>end</strong> phase needs to be
            entered before submitting uncovering details.
          </p>
        )}
        <fieldset disabled={!canEditJob && 'disabled'}>
        {isEthylFormate(treatmentType)?(
               <p>
               CT Calulation Formula : ((Lowest Initial reading + lowest final 
   reading) ÷ 2 readings) x number of 
   hours
             </p>
      ):null}
      

          <Row>
          {isEthylFormate(treatmentType) ? (
              <FormGroup className="col-lg-3">
                   <Label htmlFor="treatmentresultrecords">Lowest Initial</Label>
                <input
                  className="form-control-plaintext"
                  placeholder="No value"
                  type="text"
                  value={lowestValues?lowestValues.initialReadingValues.readingValue:null}
                  readOnly
                />
              </FormGroup>
            ) : null}
   {isEthylFormate(treatmentType) ? (
              <FormGroup className="col-lg-3">
                   <Label htmlFor="treatmentresultrecords">Start of Fumigation Time</Label>
                <input
                  className="form-control-plaintext"
                  placeholder="No value"
                  type="text"
                  value={lowestValues?moment(lowestValues.initialReadingValues.readTime).format('HH:mm'):null}
                  readOnly
                />
              </FormGroup>
            ) : null}

{isEthylFormate(treatmentType) ? (
              <FormGroup className="col-lg-3">
                   <Label htmlFor="treatmentresultrecords">Lowest Final</Label>
                <input
                  className="form-control-plaintext"
                  placeholder="No value"
                  type="text"
                  value={lowestValues?lowestValues.finalReadingValues.readingValue:null}
                  readOnly
                />
              </FormGroup>
            ) : null}
{isEthylFormate(treatmentType) ? (
              <FormGroup className="col-lg-3">
                   <Label htmlFor="treatmentresultrecords">Inital + Final {lowestValues?`(${lowestValues.initialReadingValues.readingValue} + ${lowestValues.finalReadingValues.readingValue})`:null}</Label>
                <input
                  className="form-control-plaintext"
                  placeholder="No value"
                  type="text"
                  value={lowestValues?(lowestValues.initialReadingValues.readingValue + lowestValues.finalReadingValues.readingValue):null}
                  readOnly
                />
              </FormGroup>
            ) : null}

{isEthylFormate(treatmentType) ? (
              <FormGroup className="col-lg-3">
                   <Label htmlFor="treatmentresultrecords">CT Value
                    {lowestValues?`((${lowestValues.initialReadingValues.readingValue} +
                       ${lowestValues.finalReadingValues.readingValue})/2)*${numOfHours}`:null}</Label>
                <input
                  className="form-control-plaintext"
                  placeholder="No value"
                  type="text"
                  value={lowestValues?Math.round(((((lowestValues.initialReadingValues.readingValue +
                  lowestValues.finalReadingValues.readingValue)/2)*numOfHours)+Number.EPSILON)*100)/100:null}
                  readOnly
                />
              </FormGroup>
            ) : null}

            {isExportAndLogs(jobType) ? (
              <FormGroup className="col-lg-6">
                <Field
                  name="yardNumber"
                  label={
                    jobType.name === 'MB Logs In Hold' ? 'Hold #' : 'Yard #'
                  }
                  component={RenderField}
                  type="text"
                  readOnly
                />
              </FormGroup>
            ) : null}
            {isBreakBulkJobType(jobType) ? (
              <FormGroup className="col-lg-6">
                <Field
                  name="stackNumber"
                  label="Stack #"
                  component={RenderField}
                  type="text"
                  readOnly
                />
              </FormGroup>
            ) : null}
            {isEthylFormate(treatmentType)?   <FormGroup className="col-lg-6"></FormGroup>:null}
            {! isImportOrExportAndContainer(jobType)|| isEthylFormate(treatmentType)||isApples?(
            <FormGroup className="col-lg-6">
              <Field
                name="uncoveringTechnicianId"
                label={
                  !isMBLogsInHold(jobType)&& !isEthylFormate(treatmentType)
                    ? 'Technician *'
                    : 'Venting Technician'
                }
                component={SearchableSelect}
                placeholder="Please select a technician"
                options={technicians}
                valueKey="id"
                labelKey="name"
                validate={validationOn ? Validators.required : undefined}
              />
            </FormGroup>):null}
            {isApples?
            (
              <FormGroup className="col-lg-6">
                <Field
                  name="endDate"
                  label="End Date *"
                  component={DatePickerField}
                  defaultToEmpty
                  showTimeSelect
                  type="text"
                  placeholder="Enter End Date"
                  validate={
                    validationOn
                      ? [Validators.isValidDateAndTime, Validators.required]
                      : undefined
                  }
                  timeIntervals={1}
                />
              </FormGroup>
            ):            
            !(
              isMethylBromide(treatmentType)|| isEthylFormate(treatmentType) &&
              (isBreakBulkJobType(jobType) || !isExportAndLogs(jobType))
            )&& (
              <FormGroup className="col-lg-6">
                <Field
                  name="endDate"
                  label="End Date *"
                  component={DatePickerField}
                  defaultToEmpty
                  showTimeSelect
                  type="text"
                  placeholder="Enter End Date"
                  validate={
                    validationOn
                      ? [Validators.isValidDateAndTime, Validators.required]
                      : undefined
                  }
                  timeIntervals={1}
                />
              </FormGroup>
            )}
            {isCallington1Shot(treatmentType)?null:
            <FormGroup className="col-lg-6">
              <Field
                name="actualTemperature"
                label={isEthylFormate(treatmentType)?"Min. Temperture *":"Actual Temperature *"}
                component={RenderField}
                type="number"
                placeholder={isEthylFormate(treatmentType)?"Enter min Temperature":"Enter the Actual Temperature"}
                validate={
                  validationOn
                    ? [Validators.number, Validators.required]
                    : undefined
                }
              />
            </FormGroup>
            }
            {isEthylFormate(treatmentType)? (
              <FormGroup className="col-lg-6">
                <Field
                  name="ventStartDateTime"
                  label={'Vent Start Date/Time'}
                  component={DatePickerField}
                  defaultToEmpty
                  showTimeSelect
                  type="text"
                  placeholder="Enter Vent Date/Time"
                  timeIntervals={1}
                />
              </FormGroup>
            ) : null}
            {isBreakBulkOrExportOfLogsAndMethylBromide(
              jobType,
              treatmentType,
            ) || isMBLogsInHold(jobType) || isImportOrExportAndContainer(jobType)||isApples||isEthylFormate(treatmentType)? (
              <FormGroup className="col-lg-6">
                <Field
                  name="ventDateTime"
                  label={isEthylFormate(treatmentType)?'Vent End Date/Time':'Vent Date/Time'}
                  component={DatePickerField}
                  defaultToEmpty
                  showTimeSelect
                  type="text"
                  placeholder="Enter Vent Date/Time"
                  timeIntervals={1}
                />
              </FormGroup>
            ) : null}
            {isBreakBulkOrExportOfLogsAndMethylBromide(
              jobType,
              treatmentType,
            )  || isImportOrExportAndContainer(jobType) && !isEthylFormate(treatmentType)||isApples?(
              <FormGroup className="col-lg-6">
                <Field
                  name="unintentionalRelease"
                  label="Unintentional Release"
                  component={SearchableSelect}
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                  valueKey={'value'}
                  labelKey={'label'}
                />
              </FormGroup>
            ) : null}

            {isEthylFormate(treatmentType)||isBreakBulkOrExportOfLogsAndMethylBromide(
              jobType,
              treatmentType,
            ) ||isApples ? (
              <FormGroup className="col-lg-12">
                <Field
                  name="uncoveringComments"
                  label="Comments"
                  component={RenderField}
                  type="textarea"
                />
              </FormGroup>
            ) : null}
             <FormGroup className="col-lg-12"></FormGroup>
            <FormGroup className={isBreakBulkOrExportOfLogsAndMethylBromide(jobType,treatmentType)?"col-lg-3 align-self-center":"col-lg-6 align-self-center"}>
              <Field
                name="passed"
                label="Pass"
                component={CheckboxField}
                hidden={this.isPassedByDefault()}
              />
            </FormGroup>
            { isBreakBulkOrExportOfLogsAndMethylBromide(jobType,treatmentType)?(
            <FormGroup className="col-lg-5">
              <Field
                name="isoTankLogs"
                label={"ISO Tank"}
                component={SearchableSelect}
                placeholder="Please select ISO Tank used"
                options={isoTanksLogs}
                valueKey="name"
                labelKey="name"
              />
            </FormGroup>):null}
            { isMethylBromide(treatmentType)? <FormGroup className="col-lg-12"></FormGroup>:null}
            { isImportOrExportContainerAndMethylBromide(jobType,treatmentType)||isApples? <FormGroup className="col-lg-4 align-self-center">Recapture</FormGroup>:null}
            { isApples?null :isImportOrExportContainerAndMethylBromide(jobType,treatmentType)?
            <FormGroup className="col-md-4">
                <Field
                  name="recapturePercentage"
                  label={"Recapture target %"}
                  component={SearchableSelect}
                  options={percentage}
                  placeholder={"Please select recapture target"}
                  valueKey="id"
                  labelKey="name"
                />
              </FormGroup>:isApples||isBreakBulkOrExportOfLogsAndMethylBromide(jobType,treatmentType)?
              <FormGroup className="col-md-4">
              <Field
                name="recapturePercentage"
                label={ isApples?"Recapture target 80 %":"Recapture target 90 %"}
                component={RenderField}
                readOnly={true}
              />
            </FormGroup> :null
            }
             { isBreakBulkOrExportOfLogsAndMethylBromide(jobType,treatmentType)?
                <FormGroup className="col-md-4">
              </FormGroup>:null}
            {isApples|| isImportOrExportContainerAndMethylBromide(jobType,treatmentType)||isBreakBulkOrExportOfLogsAndMethylBromide(jobType,treatmentType)?(
        <FieldArray
          name="recaptureReadingItem"
          component={RecaptureReadingsFieldArray}
          readOnly={!canEditJob}
          jobId={jobId}
          job={currentJob}
          onEditing={this.onEditing.bind(this)}
          currentTreatmentResultRecord={currentTreatmentResultRecord}
        />):null}
            <FormGroup className="col-12">
              <Button
                type="submit"
                className="mt-3"
                color="primary"
                disabled={showNoEndPhaseMessage}
              >
                Save
              </Button>
            </FormGroup>
          </Row>
        </fieldset>
      </div>
    );
  }
}

TreatmentResultRecordsUncoveringSection.propTypes = propTypes;

export default TreatmentResultRecordsUncoveringSection;
