import TreatmentTypeIds from 'constants/TreatmentTypeIds';
import {
  isExportAndLogsOrBreakBulk,
  isImportAndHeatTreatment,
  isContainerOrBreakBulkOrOther,
  isImportOrExportAndContainer
} from 'util/JobTypeHelper';
import {isEthylFormate} from 'util/TreatmentTypeHelper'
import moment from 'moment';
import { isExportOther } from './JobTypeHelper';

export const signoffWarnings = {
  actualTemperature: {
    showWarning: showActualTemperatureWarning,
    message: `This job should not be signed off - Minimum temperature has not been met`,
  },
  treatmentPeriod: {
    showWarning: showTreatmentPeriodWarning,
    message: `This job should not be signed off - Treatment period has not been met`,
  },
  treatmentResultNotPassed: {
    showWarning: showTreatmentResultNotPassedWarning,
    message: `This job should not be signed off - Record has not passed`,
  },
  treatmentRecaptureNotEntered:{
    showWarning:showRecaptureMissing,
    message: `This job should not be signed off - Recapture data missing`,
  },
  treatmentMissingReadingTimes:{
    showWarning:showMissingReadingTimes,
    message: 'This job should not be signed off - Readings are missing'

  }


};

function showActualTemperatureWarning({
  treatmentResults,
  treatmentDetails,
  treatmentResultRecords,
  type,
}) {
  if (!treatmentDetails || !treatmentDetails.mandatoryMinimumTemperature)
    return false;
  const treatmentTypeAllowed = isTreatmentTypeAllowed(
    treatmentDetails.treatmentTypeId,
  );
  if (!treatmentTypeAllowed) return false;
  return isExportAndLogsOrBreakBulk(type) || type.name === 'MB Logs In Hold'
    ? areAnyTreatmentResultRecordsTemeratureInvalid(
        treatmentResultRecords,
        treatmentDetails.mandatoryMinimumTemperature,
      )
      : treatmentResults !=null && treatmentResults!==undefined && treatmentResults.actualTemperature &&
        treatmentResults.actualTemperature <
          treatmentDetails.mandatoryMinimumTemperature;
}
function showRecaptureMissing({treatmentResultRecords}){
  return areAnyTreatmentResultRecordsRecaptureInvalid(treatmentResultRecords)
}
function showMissingReadingTimes(treatmentResultRecords){
  return isEthylFormate(treatmentResultRecords.treatmentDetails.treatmentTypeId)?
   isMissingReadingTimes(treatmentResultRecords):false
}

function showTreatmentPeriodWarning({
  treatmentDetails,
  treatmentResults,
  treatmentResultRecords,
  type,
}) {
  const treatmentTypeAllowed = isTreatmentTypeAllowed(
    treatmentDetails.treatmentTypeId,
  );
  if (!treatmentTypeAllowed ||isExportOther(type)) return false;
  return isExportAndLogsOrBreakBulk(type) || type.name === 'MB Logs In Hold'||isImportOrExportAndContainer(type)
  ||isContainerOrBreakBulkOrOther(type) ? areAnyTreatmentResultRecordsDatesInvalid(
        treatmentResultRecords,
        treatmentDetails.treatmentPeriod,
      )
    : isImportAndHeatTreatment(type)
    ? areTreatmentResultsDatesInvalidMinutes(
        treatmentResults,
        treatmentDetails.treatmentPeriod,
      )
    : areTreatmentResultsDatesInvalid(
        treatmentResults,
        treatmentDetails.treatmentPeriod,
      );
}

function showTreatmentResultNotPassedWarning({ treatmentResultRecords }) {
  return treatmentResultRecords.some(
    (treatmentResult) =>
      treatmentResult.passed != null && !treatmentResult.passed,
  );
}

//function showTreatmentRecaptureResultNotPassedWarning({treatmentResultRecords})

function isTreatmentTypeAllowed(treatmentTypeId) {
  const treatmentTypesToQuery = [
    TreatmentTypeIds.MethylBromide,
    TreatmentTypeIds.Pestigas,
    TreatmentTypeIds.ThermoFog,
    TreatmentTypeIds.Heat,
    TreatmentTypeIds.HydrogenCyanide,
    TreatmentTypeIds.AlphosNonVessel,
    TreatmentTypeIds.AlPhosVessel,
    treatmentTypeId.VaporPhos,
    treatmentTypeId.EthylFormate,
  ];
  return treatmentTypesToQuery.includes(treatmentTypeId);
}

function areTreatmentResultsDatesInvalid(treatmentResults, treatmentPeriod) {
  return isInvalidTreatmentPeriod(
    treatmentResults.startDate,
    treatmentResults.endDate,
    treatmentPeriod,
  );
}

function areAnyTreatmentResultRecordsTemeratureInvalid(
  treatmentResultRecords,
  mandatoryMinimumTemperature,
) {
  return treatmentResultRecords.some(
    (treatmentResult) =>
      treatmentResult.actualTemperature &&
      treatmentResult.actualTemperature < mandatoryMinimumTemperature,
  );
}
function areAnyTreatmentResultRecordsRecaptureInvalid(treatmentResultRecords){
    return treatmentResultRecords.some((treatmentResult) =>
    treatmentResult.recaptureReadingItem.length==0 && treatmentResult.efConcentrationReadingItem == undefined,);
}


function isMissingReadingTimes(treatmentResultRecords){
 var hasReadings =   treatmentResultRecords.treatmentResultRecords.length>0?
 treatmentResultRecords.treatmentResultRecords[0].efConcentrationReadingItem.some(({phase})=>phase==2)
 &&treatmentResultRecords.treatmentResultRecords[0].efConcentrationReadingItem.some(({phase})=>phase==0):false;
 return !hasReadings
}


function areAnyTreatmentResultRecordsDatesInvalid(
  treatmentResultRecords,
  treatmentPeriod,
) {
  return treatmentResultRecords.some((treatmentResult) =>
    isInvalidTreatmentPeriod(
      treatmentResult.startDate,
      treatmentResult.endDate,
      treatmentPeriod,
    ),
  );
}

function areTreatmentResultsDatesInvalidMinutes(
  treatmentResults,
  treatmentPeriod,
) {
 /* return isInvalidTreatmentPeriodMinutes(
    treatmentResults.startDate,
    treatmentResults.endDate,
    treatmentPeriod,
  );*/
  return false;
}

function isInvalidTreatmentPeriod(startDate, endDate, treatmentPeriod) {
 /* const start = moment(startDate);
  const end = moment(endDate);
  let duration = moment.duration(end.diff(start));
  let calculatedTreatmentPeriod = duration.asHours();
  return calculatedTreatmentPeriod < treatmentPeriod;*/
  console.log('isInvalidTreatmentPeriodhere')
  return false;
}

function isInvalidTreatmentPeriodMinutes(startDate, endDate, treatmentPeriod) {
  /*const start = moment(startDate);
  const end = moment(endDate);
  let duration = moment.duration(end.diff(start));
  let calculatedTreatmentPeriod =
    parseInt(duration.asHours(), 10) * 60 + duration.minutes();
  return calculatedTreatmentPeriod < treatmentPeriod;*/
  console.log('isnInvalidTreatmentPeriodMinutesHere')
  return false;
}

export const jobCertificationWarnings = {
  stopCredit: {
    showWarning: showStopCreditWarning,
    message: `The Treatment Certificate cannot be issued because Stop Credit is ticked`,
  },
  doNotCertify: {
    showWarning: showDoNotCertifyWarning,
    message: `The Treatment Certificate cannot be issued because Do Not Certify is ticked`,
  },
  inactive: {
    showWarning: showInactiveWarning,
    message: `The Treatment Certificate cannot be issued because client is inactive`,
  },
};

function showStopCreditWarning({ stopCredit }) {
  return stopCredit;
}

function showDoNotCertifyWarning({ doNotCertify }) {
  return doNotCertify;
}
function showInactiveWarning({ inactive }) {
  return inactive;
}
